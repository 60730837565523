import axios from 'axios'
import store from '@/store'

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const HTTPOKR = axios.create({
    baseURL: process.env.VUE_APP_API_OKR,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const HTTPUSERINFO = axios.create({
    baseURL: process.env.VUE_APP_API_USERINFO,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const HTTPANNOTATOR = axios.create({
    baseURL: process.env.VUE_APP_API_ANNOTATOR,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const HTTPMATRICULAS = axios.create({
    baseURL: process.env.VUE_APP_API_MATRICULAS,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const HTTPENTITIES = axios.create({
    baseURL: process.env.VUE_APP_API_ENTITIES,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


const HTTPLOGS = axios.create({
    baseURL: process.env.VUE_APP_API_LOGS,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const HTTPNERFIX = axios.create({
    baseURL: process.env.VUE_APP_API_NERFIX,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const HTTPAUTH = axios.create({
    baseURL: process.env.VUE_APP_API_AUTH,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

const setHeadersAuthorization = () => {
    HTTP.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationokr = () => {
    HTTPOKR.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationannotator = () => {
    HTTPANNOTATOR.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationmatriculas = () => {
    HTTPMATRICULAS.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationentities = () => {
    HTTPENTITIES.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationlogs = () => {
    HTTPLOGS.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationnerfix = () => {
    HTTPNERFIX.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationuserinfo = () => {
    HTTPUSERINFO.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

const setHeadersAuthorizationauth = () => {
    HTTPUSERINFO.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}

// const setHeadersResponseBLOB = () => {
//     HTTP.defaults.headers.common['Content-Type'] =
//         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//     HTTP.defaults.headers.common['responseType'] = 'arraybuffer'
// }

export const ApiService = {
    getAll(path_name) {
        setHeadersAuthorization()
        return HTTP.get(path_name)
    },

    get(path_name, id) {
        setHeadersAuthorization()
        return HTTP.get(`${path_name}/${id}`)
    },

    getByPath(path) {
        setHeadersAuthorization()
        return HTTP.get(path)
    },

    getPDF(path) {
        setHeadersAuthorization()
        return HTTP.get(path, {
            responseType: 'blob'
        })
    },

    post(path_name, data, config) {
        setHeadersAuthorization()
        return HTTP.post(path_name, data, config)
    },

    update(path_name, id = '', data) {
        setHeadersAuthorization()
        return HTTP.put(`${path_name}/${id}`, data)
    },

    delete(path_name, id) {
        setHeadersAuthorization()
        return HTTP.delete(`${path_name}/${id}`)
    },

    downloadDocument(path_name, config) {
        setHeadersAuthorization()
        return HTTP.get(`${path_name}`, config)
    },

    uploadDocument(path_name, data, getStatus) {
        setHeadersAuthorization()
        return HTTP.post(`${path_name}`, data, {
            onUploadProgress: progress => {
                if (getStatus) {
                    getStatus(data, progress)
                }
            }
        })
    }
}

export const Apiokr = {
    getAll(path_name) {
        setHeadersAuthorizationokr()
        return HTTPOKR.get(path_name)
    },


}

export const Apiannotator = {
    getAll(path_name) {
        setHeadersAuthorizationannotator()
        return HTTPANNOTATOR.get(path_name)
    },
    post(path_name, data, config) {
        setHeadersAuthorizationannotator()
        return HTTPANNOTATOR.post(path_name, data, config)
    },
    postVerify(path_name, data, config) {
        setHeadersAuthorizationnerfix()
        return HTTPNERFIX.post(path_name, data, config)
    }


}

export const Apientities = {
    getAll(path_name) {
        setHeadersAuthorizationentities()
        return HTTPENTITIES.get(path_name)
    },
    post(path_name, data, config) {
        setHeadersAuthorizationentities()
        return HTTPENTITIES.post(path_name, data, config)
    },



}

export const Apiuserinfo = {
    getAll(path_name) {
        setHeadersAuthorizationuserinfo()
        return HTTPUSERINFO.get(path_name)
    },
    post(path_name, data, config) {
        setHeadersAuthorizationuserinfo()
        return HTTPUSERINFO.post(path_name, data, config)
    },



}

export const Apimatriculas = {
    getAll(path_name) {
        setHeadersAuthorizationmatriculas()
        return HTTPMATRICULAS.get(path_name)
    },
    post(path_name, data, config) {
        setHeadersAuthorizationmatriculas()
        return HTTPMATRICULAS.post(path_name, data, config)
    }

}

export const Apilogs = {
    getAll(path_name) {
        setHeadersAuthorizationlogs()
        return HTTPLOGS.get(path_name)
    },
    post(path_name, data, config) {
        setHeadersAuthorizationlogs()
        return HTTPLOGS.post(path_name, data, config)
    }

}

export const Apiauth = {
    getAll(path_name) {
        setHeadersAuthorizationauth()
        return HTTPAUTH.get(path_name)
    },
    post(path_name, data, config) {
        setHeadersAuthorizationauth()
        return HTTPAUTH.post(path_name, data, config)
    }

}