export default {
    LOGIN_SUCCESS: (state, { identity, access_token }) => {
        state.identity = identity
        state.access_token = access_token
        state.error = false
    },
    LOGOUT: state => {
        state.username = ''
        state.access_token = ''
        state.error = false
    },
    LOGIN_ERROR: state => {
        state.error = true
    },
    RESET_ERROR: state => {
        state.error = false
    }
}