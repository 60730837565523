export default {

    SET_FIRST_LAYER:(state,payload) =>{
        state.firstlayer = {
            ...state.firstlayer,
            ...payload
        }
    },
    SET_SECOND_LAYER:(state,payload) =>{
        state.secondlayer = {
            ...state.secondlayer,
            ...payload
        }
    },
    SET_THIRD_LAYER:(state,payload) =>{
        state.thirdlayer = {
            ...state.thirdlayer,
            ...payload
        }
    },
    SET_ENTITIES_TYPES:(state,payload) =>{
        state.entitiestypes = payload
    },

}