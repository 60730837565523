import jwt_decode from 'jwt-decode'
import { login } from '@/services/login.service.js'

export default {
    async login({ commit }, credentials) {
        try {
            const { username, password } = credentials

            const {
                data: { data }
            } = await login(username, password)
            const { access_token } = data

            const { identity } = jwt_decode(access_token)

            commit('LOGIN_SUCCESS', { identity, access_token })

            window.location.href = '/'
        } catch (e) {
            console.log(e)
            commit('LOGIN_ERROR')
        }
    },

    logout({ commit }) {
        commit('LOGOUT')
        window.localStorage.removeItem('admin-beaver')
        window.location.href = '/login'
    },

    resetLoginError(context) {
        context.commit('RESET_ERROR')
    }
}