import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/'

Vue.use(VueRouter);
const routes = [{
        path: "/",
        component: () =>
            import ('@/views/Main/Main.vue'),
        children: [{
                path: '/',
                name: 'Home',
                meta: {
                    title: 'Home | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Home/Home.vue')
                }
            },
            {
                path: '/newuser',
                name: 'Newuser',
                meta: {
                    title: 'Usuários | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Newuser/Newuser.vue')
                }
            },
            {
                path: '/contracts',
                name: 'Contratos',
                meta: {
                    title: 'Contratos | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Contracts/Contracts.vue')
                }
            },
            {
                path: '/monitor-revisor',
                name: 'Monitor Revisor',
                meta: {
                    title: 'Monitor | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/MonitorRevisor/MonitorRevisor.vue')
                }
            },
            {
                path: '/classificador',
                name: 'Classificador',
                meta: {
                    title: 'Classificador | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Matriculas/Matriculas.vue')
                }
            },
            {
                path: '/monitor-revisorv2',
                name: 'Monitor Revisor V2',
                meta: {
                    title: 'Monitor V2 | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/MonitorRevisorv2/MonitorRevisorv2.vue')
                }
            },
            {
                path: '/matriculasannotator',
                name: 'Matrículas Annotator',
                meta: {
                    title: 'Matrículas Annotator | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Matriculasannotator/Matriculasannotator.vue')

                }
            },
            {
                path: '/verifier/areas',
                name: 'Annotator Verifier',
                meta: {
                    title: 'Annotator Verifier | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Annotatorarea/Annotatorarea.vue')
                }
            },
            {
                path: '/verifier/comprador',
                name: 'Annotator Comprador',
                meta: {
                    title: 'Annotator Comprador | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Annotatorbuyer/Annotatorbuyer.vue')
                }
            },
            {
                path: '/annotator',
                name: 'Annotator',
                meta: {
                    title: 'Annotator | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Annotator/Annotator.vue')
                }
            },
            {
                path: '/annotatorparagraphs',
                name: 'Annotatorparagraphs',
                meta: {
                    title: 'Annotator Paragraphs | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Annotatorparagraphs/Annotatorparagraphs.vue')
                }
            },
            {
                path: '/revannotator',
                name: 'Annotator Fix',
                meta: {
                    title: 'Annotator Fix | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Revannotator/Revannotator.vue')
                }
            },
            {
                path: '/okrs',
                name: `OKR's`,
                meta: {
                    title: `OKR's`
                },
                components: {
                    application: () =>
                        import ('@/views/Okrs/okrs.vue')
                }
            },
            {
                path: '/customer',
                name: 'Clientes',
                meta: {
                    title: 'Customer | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Customer/Customer.vue')
                }
            },
            {
                path: '/dashboard-client',
                name: 'Dashboard Client',
                meta: {
                    title: 'Dashboard Client | Beaver'
                },
                components: {
                    application: () =>
                        import ('@/views/Customer/Dashboard/Dashboard.vue')
                }
            },
            {
                path: '/processlog',
                name: 'Matrícula Process Log',
                meta: {
                    title: 'Matrícula Process Log'
                },
                components: {
                    application: () =>
                        import ('@/views/Processlog/processlog.vue')
                }
            },
            {
                path: '/entities',
                name: 'Entities',
                meta: {
                    title: 'Entities'
                },
                components: {
                    application: () =>
                        import ('@/views/Entities/Entities.vue')
                }
            },
            {
                path: '/reviewannotator',
                name: 'Review Annotator',
                meta: {
                    title: 'Review Annotator'
                },
                components: {
                    application: () =>
                        import ('@/views/Reviewannotator/Reviewannotator.vue')
                }
            },
            {
                path: '/rating',
                name: 'Rating',
                meta: {
                    title: 'Rating'
                },
                components: {
                    application: () =>
                        import ('@/views/Rating/Rating.vue')
                }
            },
            {
                path: '/billing',
                name: 'Billing',
                meta: {
                    title: 'Billing'
                },
                components: {
                    application: () =>
                        import ('@/views/Billing/billing.vue')
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Beaver | Login',
            notAuthorization: true,
            onlyDisconnected: true
        },
        component: () =>
            import ('@/views/login/login.vue')
    },
    {
        path: "*",
        redirect: '/'
    }


];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    store.dispatch('changeSiderbarstate', true)
    document.title = to.meta.title

    const isNotAuthorization = to.matched.some(
        record => record.meta.notAuthorization
    )
    const isOnlyDisconnected = to.matched.some(
        record => record.meta.onlyDisconnected
    )

    const authenticated = !!store.getters.getToken
    if (!isNotAuthorization && !authenticated) {
        return next('/login')
    }

    if (authenticated && isOnlyDisconnected) {
        return next('/')
    }

    next()
})



export default router;