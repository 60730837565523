import Vue from "vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vue2TouchEvents from 'vue2-touch-events'
import JsonExcel from "vue-json-excel";
import { duplicateObject } from "./helpers/helpers";
import { watchscroll, duplicate, returnmax, orderarray, pad, mousemove } from "brutils1212"
import { savelog, annotator, tokenTest } from "./plugins";

Vue.use(Vue2TouchEvents)
Vue.use(Vuelidate)
Vue.use(VueToast, { position: 'top-right', duration: 5000 })
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(watchscroll);
Vue.use(returnmax);
Vue.use(tokenTest);
Vue.use(duplicate);
Vue.use(orderarray);
Vue.use(savelog);
Vue.use(pad);

Vue.use(mousemove);
Vue.use(annotator,'/revannotator');
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false;
Vue.prototype.$duplicate = duplicateObject

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');