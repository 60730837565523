import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'

import Auth from './modules/auth'
import Client from './modules/client'
import UI from './modules/UI'
import Review from './modules/review'
import Entities from './modules/Entities'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: 'admin-beaver',
    storage: window.localStorage
})

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Auth,
        Client,
        UI,
        Review,
        Entities
    },
    plugins: [vuexLocal.plugin]
});