export default {
    setFirstlayer({ commit }, payload) {
        commit('SET_FIRST_LAYER', payload)
    },
    setSecondlayer({ commit }, payload) {
        commit('SET_SECOND_LAYER', payload)
    },
    setThirdlayer({ commit }, payload) {
        commit('SET_THIRD_LAYER', payload)
    },
    setEntitiestypes({ commit }, payload) {
        commit('SET_ENTITIES_TYPES', payload)
    },
}