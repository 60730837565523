import { Apilogs } from '@/axios'

export const create = (data) => {
    return Apilogs.post('create_data', data)
}

export const get = (data) => {
    return Apilogs.post('query_logs', data)
}

export const getMatriculasannotator = (data) => {
    return Apilogs.post('matricula_annotator_logs', data)
}

export const getRate = () => {
    return Apilogs.post('rating_logs', {})
}

export const getCompleterate = (data = {}) => {
    return Apilogs.post('rating_complete_logs', data)
}