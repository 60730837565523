export default {
    firstlayer: {
        key:'',
        object:{},
        returned:{}
    },
    secondlayer: {
        key:'',
        _key:'',
        object:{},
        returned:{}
    },
    thirdlayer: {
        key:'',
        _key:'',
        __key:'',
        object:{},
        returned:{}
    },
    entitiestypes:[]
}