import { create } from '../services/logs.services'


export const watchscroll = {
    install: function(Vue) {
        var scroller = new Vue({
            data: {
                scrollY: 0,
                resize: 0,
            },
        });
        window.addEventListener("scroll", () => {
            scroller.scrollY = window.scrollY;
        });
        Vue.prototype.$windowY = scroller.$data;
    },
};

export const savelog = {
    install: function(Vue) {
        Vue.prototype.$savelog = (groupName, streamName, data) => {
            let _cloudwatch = {
                logGroupName: groupName,
                logStreamName: streamName,
                logs: data
            }
            create(_cloudwatch)
                .then(() => {
                    console.log('Save log')
                })
                .catch((e) => {
                    this.$tokentest(e.response.status)
                    console.error('Error save log')
                })
        }
    }
}

export const mousemove = {
    install: function(Vue) {
        var mousemove = new Vue({
            data: {
                mousey: 0,
                mousex: 0,
                timer: 0,
                time: 0,
            },
            created() {
                this.callInterval();
                document.addEventListener("mousemove", () => {
                    this.timer = 0;
                });
            },
            methods: {
                callInterval(time = 1000) {
                    return setInterval(() => {
                        this.timer += time;
                    }, time);
                },
            },
        });

        Vue.prototype.$mousemove = mousemove.$data;
    },
};

export const duplicate = {
    install: function(Vue) {
        Vue.prototype.$duplicate = (data) => {
            return JSON.parse(JSON.stringify(data));
        };
    },
};

export const returnmax = {
    install: function(Vue) {
        Vue.prototype.$returnmax = (array, field) => {
            return Math.max.apply(
                null,
                array.map((val) => {
                    return val[field];
                })
            );
        };
    },
};

export const orderarray = {
    install: function(Vue) {
        Vue.prototype.$orderarray = (array, field, order = "asc") => {
            if (order === "desc") {
                return array.sort((a, b) => {
                    return a[field] - b[field];
                });
            } else {
                return array.sort((a, b) => {
                    return b[field] - a[field];
                });
            }
        };
    },
};

export const tokenTest = {
    install: function(Vue) {
        Vue.prototype.$tokentest = code => {
            if (code === 401) {
                Vue.$toast.error('Token expirada, faça novamente o login.')
                window.localStorage.removeItem('admin-beaver')
                return (window.location.href = '/login')
            }
        }
    }
}

export const pad = {
    install: function(Vue) {
        Vue.prototype.$pad = (val) => {
            var valString = val + "";
            if (valString.length < 2) {
                return "0" + valString;
            } else {
                return valString;
            }
        };
    },
};


export const annotator =  {
    install: function(Vue,data='/revannotator') {
        
        let types = [
            ["PER", "pessoa", '1'],
            ["DOC", "documento", '2'],
            ["PRO", "profissão", '3'],
            ["NAT", "nacionalidade", '4'],
            ["REG", "regime de casamento", '5'],
            ["LOG", "logradouro", '6'],
            ["NUM", "número da rua", '7'],
            ["CMP", "complemento", '8'],
            ["CDM", "condomínio", '9'],
            ["NGB", "bairro", '0'],
            ["CIT", "cidade", 'q'],
            ["ARE", "área", 'w'],
            ["DAT", "data", 'e'],
            ["MON", "valor monetário", 'r'],
            ["RAV", "registro / averbação", 't'],
            ["ORG", "órgão público", 'y'],
            ["PCT", "porcentagem", 'u'],
            ["PCL", "número de parcelas", 'i'],
        ];

        function returnmax(array, field) {
            return Math.max.apply(
                null,
                array.map((val) => {
                    return val[field];
                })
            );
        }

        function orderarray(array, field, order = "asc") {
            if (order === "desc") {
                return array.sort((a, b) => {
                    return a[field] - b[field];
                });
            } else {
                return array.sort((a, b) => {
                    return b[field] - a[field];
                });
            }
        }

        function duplicate(data) {
            return JSON.parse(JSON.stringify(data));
        };

        function singleSpanFactory(text, start, end, index, type) {
            return {
                text: text,
                start,
                end,
                index,
                type: type + ' notPrint',
                label: type,
            }
        }

        function spanFactory(text, start, end, index, type, label, template) {
            return {
                text: text.slice(start, end).toString(),
                start,
                end,
                index,
                type,
                label,
                id: parseInt(Math.random() * (20000000000000000000 - 1000000000000000) + 1000000000000000),
                html() {
                    switch (template) {
                        case 'ner_double':
                            if (this.type !== 'NON') {
                                return `<span class="${this.label} ${this.type}" title="${this.label}" id="${start}/${end}/false/${this.id}">${this.text}</span>`
                            } else {
                                return `<span class="${this.label}" id="${start}/${end}/true/${this.id}">${this.text}</span>`
                            }
                        case 'multiclass':
                            if (this.type !== 'NON') {
                                return `<span class="${this.label}" title="${this.label}" id="${start}/${end}/false/${this.id}">${this.text}</span>`
                            } else {
                                return `<span class="${this.label}" id="${start}/${end}/true/${this.id}">${this.text}</span>`
                            }
                    }


                }
            }
        }

        function populateSpans(annotation) {
            annotation['nospan'] = true
            annotation['spans'] = [];
            annotation['counter']++
                annotation['spans'].push(spanFactory(annotation['text'], 0, annotation['text'].length, 0, 'NON'))

            return {
                annotation: annotation
            }
        }

        function spansMount(annotation) {
            let template = annotation['template']
            let treatedText = []
            if (!annotation['nospan']) {
                for (var i = 0; i < annotation['spans'].length; i++) {
                    if (i == 0) {
                        if (annotation['spans'].length === 1) {
                            treatedText.push(spanFactory(annotation['text'], 0, annotation['spans'][i].start, annotation['spans'][i].index, 'NON', 'NON', template))
                            treatedText.push(spanFactory(annotation['text'], annotation['spans'][i].start, annotation['spans'][i].end, annotation['spans'][i].index, annotation['spans'][i].type, annotation['spans'][i].label, template))
                            treatedText.push(spanFactory(annotation['text'], annotation['spans'][i].end, annotation['text'].length, annotation['spans'][i].index, 'NON', 'NON', template))
                        } else {
                            treatedText.push(spanFactory(annotation['text'], 0, annotation['spans'][i].start, annotation['spans'][i].index, 'NON', 'NON', template, 'nomarked'))
                            treatedText.push(spanFactory(annotation['text'], annotation['spans'][i].start, annotation['spans'][i].end, annotation['spans'][i].index, annotation['spans'][i].type, annotation['spans'][i].label, template))
                        }
                    } else if (i == annotation['spans'].length - 1) {

                        treatedText.push(spanFactory(annotation['text'], annotation['spans'][i - 1].end, annotation['spans'][i].start, annotation['spans'][i].index, 'NON', 'NON', template))
                        treatedText.push(spanFactory(annotation['text'], annotation['spans'][i].start, annotation['spans'][i].end, annotation['spans'][i].index, annotation['spans'][i].type, annotation['spans'][i].label, template))
                        treatedText.push(spanFactory(annotation['text'], annotation['spans'][i].end, annotation['text'].length, annotation['spans'][i].index, 'NON', 'NON', template))
                    } else {

                        treatedText.push(spanFactory(annotation['text'], annotation['spans'][i - 1].end, annotation['spans'][i].start, annotation['spans'][i].index, 'NON', 'NON', template))
                        treatedText.push(spanFactory(annotation['text'], annotation['spans'][i].start, annotation['spans'][i].end, annotation['spans'][i].index, annotation['spans'][i].type, annotation['spans'][i].label, template))

                    }
                }
            } else {
                treatedText.push(spanFactory(annotation['text'], 0, annotation['text'].length, 0, 'NON', 'NON', template))

            }


            return treatedText
        }

        function paragraphMount(annotation) {
            if (document.getElementById(`txt-${annotation['id']}`)) {
                document.getElementById(`txt-${annotation['id']}`).innerHTML = ''
                annotation['spansview'].forEach(el => {
                    document.getElementById(`txt-${annotation['id']}`).innerHTML += el.html()
                })
            } else {
                setTimeout(() => {
                    paragraphMount(annotation)
                }, 300)
            }

        }

        function backupModel(annotation) {
            annotation['spansBackup'] = [];
            annotation['spansBackup'] = duplicate(annotation['spans'])
            return {
                annotation: annotation
            }
        }

        function spansAdd(annotation) {
            annotation['spans'] = orderarray(annotation['spans'], 'start', 'desc')
            annotation['spansview'] = spansMount(annotation)
            annotation['spansview'].filter((v, index, object) => {
                if (v.start === v.end) object.splice(index, 1);
            });
            paragraphMount(annotation)
        }

        function addSpansModel(annotation, span) {

            if (annotation['nospan'] === true) {
                annotation['nospan'] = false
                annotation['spans'] = []
                annotation['spans'].push(span)
                    //console.log(this.nerModel.spans)
            } else {
                annotation['spans'].push(span)
            }
            spansAdd(annotation)
        }

        Vue.prototype.$typeSelect = (type) => {
            Vue.$toast.info(`Anotador ${type} selecionado`)
            document.getElementsByClassName('annotators').forEach((val) => {
                val.classList.remove('active')
            })
            document.querySelector(`.annotators.${type}`).classList.add(["active"])
            buttonEvent.$data['typeselected'] = type
            
        }

        Vue.prototype.$types = () => {
            return types
        }

        Vue.prototype.$restartParagraph = (annotation) => {
            return new Promise((resolve) => {
                annotation['spans'] = duplicate(annotation['spansSend'])
                annotation['counter'] = annotation['spans'].length
                annotation['nospan'] = false
                annotation['spansview'] = spansMount(annotation)
                paragraphMount(annotation)
                resolve(annotation)
            })

        }

        Vue.prototype.$cleanAnnotation = (annotation) => {
            return new Promise((resolve, reject) => {
                populateSpans(annotation)
                annotation['spansview'] = spansMount(annotation)
                paragraphMount(annotation)
                resolve(annotation)
            })
        }

        Vue.prototype.$getSelection = (annotation, selected, literalSelector) => {
            return new Promise((resolve, reject) => {
                let actualspanlength
                if (annotation['nospan']) {
                    actualspanlength = returnmax(annotation['spans'], 'index')
                } else {
                    actualspanlength = returnmax(annotation['spans'], 'index') + 1
                }
                if (selected === '') {
                    return Vue.$toast.info(`Selecione um anotador`)
                }
                annotation['backupspans'] = backupModel(annotation)['annotation']
                let sel = window.getSelection()
                let { anchorOffset, focusOffset } = sel
                let text = sel.toString();
                let rg = document.createRange();
                let baseArgs
                let focusId
                let focusEnd
                let editEnd
                let start
                let end
                let edit
                rg.setStart(sel.anchorNode, sel.anchorOffset);
                rg.setEnd(sel.focusNode, sel.focusOffset);
                //Sentido anti-horário
                if (!rg.toString()) {
                    baseArgs = sel.focusNode.parentNode.id
                    focusId = sel.baseNode.parentNode.id
                    anchorOffset = sel.focusOffset
                    focusOffset = sel.anchorOffset
                }
                //Sentido horário
                else {
                    baseArgs = sel.baseNode.parentNode.id
                    focusId = sel.focusNode.parentNode.id

                }
                // Captando propriedades da seleção
                focusEnd = parseInt(focusId.split('/')[1])
                editEnd = focusId.split('/')[2]
                baseArgs = baseArgs.split('/')
                start = parseInt(baseArgs[0])
                end = parseInt(baseArgs[1])
                edit = baseArgs[2]
                    //Procedendo enquanto o tipo selecionado for exclusão
                if (selected !== 'NON') {
                    if (editEnd === 'false' || edit === 'false' || (end !== focusEnd)) {
                        annotation['spans'].filter((v, index, object) => {
                            if (v.start === start && v.end === end) {
                                object.splice(index, 1);
                            }
                        });
                        if (annotation['spans'].length === 0) {
                            populateSpans(annotation)
                        }
                        //Montando novo parágrafo do anotador após a exclusão
                        spansAdd(annotation)
                        return
                    } else {
                        //Procedendo enquanto o selecionador for do tipo literal
                        if (literalSelector) {
                            if (focusOffset === anchorOffset) {
                                Vue.$toast.info(`Selecione mais de uma palavra`)
                            }
                            if (end - start == end) {
                                const positionEnd = focusOffset
                                const positionStart = anchorOffset
                                addSpansModel(annotation, singleSpanFactory(text, positionStart, positionEnd, actualspanlength, selected))

                            } else {
                                const positionStart = start + anchorOffset
                                const positionEnd = start + focusOffset
                                addSpansModel(annotation, singleSpanFactory(text, positionStart, positionEnd, actualspanlength, selected))
                            }
                        } else {
                            // Detect if selection is backwards
                            var range = document.createRange();
                            range.setStart(sel.anchorNode, sel.anchorOffset);
                            range.setEnd(sel.focusNode, sel.focusOffset);
                            var backwards = range.collapsed;
                            range.detach();

                            // modify() works on the focus of the selection
                            var endNode = sel.focusNode,
                                endOffset = sel.focusOffset;
                            sel.collapse(sel.anchorNode, sel.anchorOffset);

                            var direction = [];
                            if (backwards) {
                                direction = ["backward", "forward"];
                            } else {
                                direction = ["forward", "backward"];
                            }

                            sel.modify("move", direction[0], "character");
                            sel.modify("move", direction[1], "word");
                            sel.extend(endNode, endOffset);
                            sel.modify("extend", direction[1], "character");
                            sel.modify("extend", direction[0], "word");

                            // remove eventual space at the end of the word
                            if (sel.toString().slice(-1) === " ") {
                                sel.modify("extend", "backward", "character");
                            }

                            if (end - start == end) {

                                if (backwards) {
                                    if (sel.focusOffset > sel.anchorOffset || sel.focusOffset === sel.anchorOffset) return
                                    const positionEnd = sel.anchorOffset
                                    const positionStart = sel.focusOffset
                                    addSpansModel(annotation, singleSpanFactory(sel.toString(), positionStart, positionEnd, actualspanlength, selected))
                                } else {
                                    if (sel.focusOffset < sel.anchorOffset || sel.focusOffset === sel.anchorOffset) return
                                    const positionEnd = sel.focusOffset
                                    const positionStart = sel.anchorOffset
                                    addSpansModel(annotation, singleSpanFactory(sel.toString(), positionStart, positionEnd, actualspanlength, selected))
                                }


                            } else {
                                if (backwards) {

                                    if (sel.focusOffset > sel.anchorOffset || sel.focusOffset === sel.anchorOffset) return
                                    const positionStart = start + sel.focusOffset
                                    const positionEnd = start + sel.anchorOffset
                                    addSpansModel(annotation, singleSpanFactory(sel.toString(), positionStart, positionEnd, actualspanlength, selected))
                                } else {
                                    if (sel.focusOffset < sel.anchorOffset || sel.focusOffset === sel.anchorOffset) return
                                    const positionStart = start + sel.anchorOffset
                                    const positionEnd = start + sel.focusOffset
                                    addSpansModel(annotation, singleSpanFactory(sel.toString(), positionStart, positionEnd, actualspanlength, selected))
                                }

                            }

                        }



                    }
                    //Procedendo para exclusão
                } else {

                    if (editEnd === 'true' || edit === 'true' || (end !== focusEnd)) {

                        Vue.$toast.info(`Range não está selecionado`)
                    }
                    annotation['spans'].filter((v, index, object) => {
                        if (v.start === start && v.end === end) {
                            object.splice(index, 1);
                        }
                    });
                    if (annotation['spans'].length === 0) {
                        populateSpans(annotation)
                    }
                    //Montando novo parágrafo do anotador após a exclusão
                    annotation['spansview'] = spansMount(annotation)
                    paragraphMount(annotation)

                }
                resolve(annotation)
            })


        }

        Vue.prototype.$nerMount = (template, annotation) => {
            if (annotation['spans'].length === 0) {
                annotation = populateSpans(annotation)
                annotation['spansSend'] = duplicate(annotation['spans'])
            } else {
                annotation['nospan'] = false
                annotation['template'] = template
                annotation['paragraph'] = ''
                annotation['spansSend'] = duplicate(annotation['spans'])
                annotation['counter'] = annotation['spans'][annotation['spans'].length - 1].index + 1
            }
            annotation['spansview'] = spansMount(annotation)
            paragraphMount(annotation)

            return {
                annotation: annotation
            }

        }

        Vue.prototype.$getTooltip = (type, text, event) => {
            let tooltip = document.createElement('div')
            tooltip.classList.add([`tiptool`], [`${type}`], ['shadow-lg'], ['rounded'])
            tooltip.id = 'tiptool'
            tooltip.style.width = 'auto'
            tooltip.style.height = 'auto'
            tooltip.style.padding = '5px'
            tooltip.style.textAlign = 'center'
            tooltip.style.zIndex = '50000'
            tooltip.style.top = `${event.pageY - 50}px`
            tooltip.style.left = `${event.pageX - 40}px`
            tooltip.style.position = 'absolute'
            tooltip.innerHTML += text.toUpperCase()

            document.body.appendChild(tooltip)
        }
        Vue.prototype.$removeTooltip = () => {
            document.querySelector('#tiptool').remove()
        }

        var generalevent 

        var buttonEvent = new Vue({
            data: {
                typeselected: ''
            },
            created() {
                let types = Vue.prototype.$types()
                document.addEventListener('keydown', (e) => {
                    generalevent = e
                    if(window.location.pathname === data) {
                    for (const i in types) {
                        if (types[i][2] === e.key) {
                            Vue.prototype.$typeSelect(types[i][0])

                        }
                    }
                   }
                })
            },

        });
        Vue.prototype.$keydownevent = generalevent
        Vue.prototype.$typeselected = buttonEvent.$data;


    }
}